<template>
  <q-dialog
    :value="true"
    full-height
    full-width
    persistent
    @hide="onHide"
  >
    <q-layout class="bg-white" container view="hHh LpR fff">
      <q-header>
        <q-toolbar>
          <q-btn
            v-close-popup
            flat
            icon="fas fa-arrow-left"
          />
          <q-toolbar-title>Update {{ innerValue.name }}</q-toolbar-title>
          <q-space/>
          <q-btn
            flat
            icon="fas fa-save"
            @click="submit"
          />
        </q-toolbar>
      </q-header>
      <q-page-container>
        <q-page padding>
          <update-form
            v-model="innerValue"
            @submit.prevent="submit"
          />
        </q-page>
      </q-page-container>
    </q-layout>
  </q-dialog>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import UpdateForm from 'pages/_locale/dashboard/group/UpdateForm'

  const moduleName = 'group'
  const { mapActions } = createNamespacedHelpers(
    moduleName,
  )

  export default {
    components: { UpdateForm },
    meta: {
      title: 'Dashboard',
    },
    methods: {
      ...mapActions(
        [
          'show',
          'update',
        ],
      ),
      async submit () {
        try {
          await this.update(this.innerValue)
        } catch (error) {
          return
        }

        await this.onHide()
      },
      async onHide () {
        await this.$router.push(
          `/${this.$route.params.locale}/dashboard/group`,
        )
      },
    },
    async created () {
      let response = null
      try {
        response = await this.show(
          {
            id: this.$route.params.id,
            refresh: true,
          },
        )
      } catch (error) {
        await this.onHide()
      }

      this.innerValue = response.data.data
    },
    data () {
      return {
        innerValue: {},
      }
    },
  }
</script>
